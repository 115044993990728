import { useNavigation } from "@remix-run/react";
import Spinner from "./spinner";
import { ReactElement, ReactNode } from "react";
import { Button, ButtonColors } from "./base/button";
import { CheckIcon } from "@heroicons/react/24/solid";

export const SubmitButton: React.FC<{
  color?: ButtonColors;
  disabled?: boolean;
  className?: string;
  idleContent?: ReactNode;
  idleIcon?: ReactNode;
  busyContent?: ReactNode;
  busyIcon?: ReactNode;
}> = ({
  color = "blue",
  disabled = false,
  className = "",
  idleContent = "Submit",
  busyContent = "Submitting",
  idleIcon = <CheckIcon />,
  busyIcon = <Spinner />,
}) => {
  const navigation = useNavigation();
  const isSubmitting = navigation.state != "idle";

  return (
    <Button
      disabled={disabled || isSubmitting}
      className={className}
      color={color}
      type="submit"
    >
      {isSubmitting ? (
        <>
          {busyIcon} {busyContent}
        </>
      ) : (
        <>
          {idleIcon} {idleContent}
        </>
      )}
    </Button>
  );
};
